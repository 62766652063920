import { useState, useEffect } from 'react'

export function useBreakpoint() {
  const [windowSize, setWidthWindow] = useState(0)

  useEffect(() => {
    function handleResize() {
      setWidthWindow(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
