'use client'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { GlobalStyles } from '@/styles/global'

import { AuthContextProvider } from '@/context/authContext'

import { useAppAccessibility } from '@/hooks'

import theme from '@/styles/theme'

import 'cegov-antd/dist/index.css'
import VLibras from 'vlibras-nextjs'

const queryClient = new QueryClient()

export function RootLayoutCustom({ children }: { children: React.ReactNode }) {
  const { lengthText } = useAppAccessibility()

  return (
    <AuthContextProvider>
      <VLibras forceOnload />
      <ThemeProvider theme={theme}>
        <GlobalStyles lengthText={lengthText} />

        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </ThemeProvider>
    </AuthContextProvider>
  )
}
