import { DividerProps } from '@oauth-webapp/styles'
import styled from 'styled-components'

export default {
  border: {
    radius: '1rem'
  },
  font: {
    primary:
      "var(--font-isidora-sans), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    secondary:
      "var(--font-montserrat), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    sizes: {
      xsmall: '1.5rem',
      small: '1.75rem',
      medium: '2rem',
      large: '2.25rem',
      xlarge: '3rem',
      xxlarge: '6rem',
      xxxlarge: '7.25rem'
    }
  },
  colors: {
    primary: '#2E6A25',
    primaryBright: '#3C802F',
    primary100: '#98C69A',
    primary200: '#4F994A',
    primary500: '#1F9035',
    primary600: '#1A7E2E',
    primary800: '#144C1C',
    lime: '#8AC440',
    limelight: '#A3CC66',
    limeBright: '#7EFF12',
    limeDark: '#6F9F33',
    blue: '#0E76CC',
    blueLight: '#3F8AD9',
    blueBright: '#00B5FF',
    blueDark: '#0F5F82',
    greenDark: '#4AB814',

    greenLight: '#00AA87',
    yellow: '#F3A732',
    yellowLight: '#F8C163',
    yellowBright: '#FFA22B',
    yellowDark: '#B68B1E',
    orange: '#DB5219',
    orangeLight: '#DB7C52',
    orangeBright: '#FF6921',
    orangeDark: '#B5470B',
    marine: '#344048',
    marineBright: '#3B4F62',
    white: '#F0F0F0',
    black: '#262E33',
    gray200: '#BCC9D1',
    gray400: '#546269',
    gray500: '#3F4D53',
    success: '#5FCA89',
    successLight: '#8BD9A7',
    successDark: '#3E9B4D',
    info: '#3A7AC6',
    infoLight: '#6B9BD8',
    infoDark: '#1A3954',
    warning: '#FFC945',
    warningLight: '#FFD980',
    warningDark: '#B4970A',
    danger: '#D33F24',
    dangerBright: '#FF3115',
    dangerLight: '#FF7263',
    dangerDark: '#852013',
    chartSalmon: '#FF7263',
    chartMint: '#7FE5B4',
    chartPink: '#FF71B5',
    chartPurple: '#8250FF',
    chartWine: '#E13F5A',
    background: 'rgb(34,193,195)',
    gradientPrimaryLighter:
      'linear-gradient(51deg, rgba(34,193,195,1) 0%, rgba(45,253,69,1) 100%)',
    grandientPrimary: 'linear-gradient(87.47deg, #1F490E 0%, #7EFF12 100%)',
    grandientSecundary: 'linear-gradient(87.47deg, #DB5219 0%, #FFC297 100%)',
    grandientTertiary: 'linear-gradient(87.47deg, #3D7C47 0%, #00B5FF 100%)'
  },
  spacings: {
    xxsmall: '1rem',
    xsmall: '2rem',
    small: '3rem',
    medium: '4rem',
    large: '5rem',
    xlarge: '6rem',
    xxlarge: '7rem'
  },
  breakpoints: {
    sm: '@media (max-width: 576px)',
    md: '@media (max-width: 768px)',
    lg: '@media (max-width: 992px)',
    xl: '@media (max-width: 1200px)'
  },
  zIndex: {
    modal: 1010,
    overlay: 900,
    dropdown: 800
  }
} as const

export const StyledDivider = styled.div<DividerProps>`
  width: ${(props) => (props.direction === 'vertical' ? '2px' : '3vw')};
  height: ${(props) => (props.direction === 'vertical' ? '3vh' : '2px')};
  background-color: ${({ theme, color }) =>
    color === 'white' ? theme.whiteColor : theme.blackColor};
`
