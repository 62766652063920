'use client'

import { createGlobalStyle, css } from 'styled-components'

import { resetCss } from './reset-css'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
interface GlobalStyleProps {
  lengthText: number
}

export const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
  ${resetCss}
  
  body {
    background-color: ${({ theme }) => theme.colors.white};
  }

  body,
  input,
  button {
    font-family: ${({ theme }) => theme.font.primary};

    font-weight: ${({ theme }) => theme.font.normal};
    font-size:  ${({ theme }) => theme.font.sizes.medium};
    border: none;
  }

  * {
    margin: 0;
    padding: 0;
    outline: none; 
    box-sizing: border-box;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html {
    font-size: ${(props) =>
      props.lengthText === 0 ? '50%' : `${50 + props.lengthText * 10}%`};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0;
    margin: 0;

    font-family: ${({ theme }) => theme.font.primary};

  }
  
  .body-scroll-lock {
    overflow: hidden;
  }
  
  ${({ theme, lengthText }) => css`
    ${theme.breakpoints.lg} {
      html {
        font-size: ${lengthText === 0 ? '40%' : `${40 + lengthText * 7}%`};
      }
    }
  `}


  .ant-input-group-wrapper {
    background-color: '#4AB814' !important
  }

  .ant-input-group-addon > button {
    background-color: ${({ theme }) => theme.colors.chartMint};
  }


  .ant-tabs-nav-operations,
  .ant-tabs-dropdown {
    display: none !important;
  }
  
  .cegov-container-acessibility-bar .acessibility-container{
    max-width: 1110px !important;

  .active{
    top: 30px !important;
  }
`
