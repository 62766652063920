import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/isidoraSans/IsidoraSans-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/isidoraSans/IsidoraSans-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/isidoraSans/IsidoraSans-SemiBold.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/isidoraSans/IsidoraSans-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-isidora-sans\",\"display\":\"swap\"}],\"variableName\":\"isidoraSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"600\"],\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\",\"display\":\"swap\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/lib/registry_styled_components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppAccessibilityProvider"] */ "/app/src/context/accessibilityContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayoutCustom"] */ "/app/src/layouts/RootLayoutCustom/index.tsx");
